import type { ApplicationConfig } from ".";
import { FEATURE_FLAGS } from "./utils/flags";

const token = process.env.NEXT_PUBLIC_MAPBOX_TOKEN ?? "";

export const baseConfig: ApplicationConfig = {
  featureFlags: FEATURE_FLAGS,
  apiKeys: {
    mapBoxToken: token,
  },
  organization: {
    demoId: "",
  },
  documentation: {
    url: "",
  },
  user: {
    provider: "arup-azure-ad",
  },
};
