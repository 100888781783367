import { createContext } from "react";

import type {
  CreateSettingsResponse,
  ViewModel,
} from "@/apis/services/OrganizationService";
import { useGetOrgSettings } from "@/hooks/useAllOrganizations";
import type { Flag } from "@/hooks/useFlags";
import { useGetFeatureFlags } from "@/hooks/useFlags";
import { useUserInfo } from "@/hooks/useUserInfo";
import { deepMerge } from "@/utils/utils";

import { defaultOrganizationSettings } from "./defaultOrganizationSettings";

export interface IOrganizationContext {
  organizationSettings: CreateSettingsResponse;
  isLoadingSettings: boolean;
  flags: Flag[];
}

const defaultSettings: IOrganizationContext = {
  organizationSettings: {
    views: defaultOrganizationSettings as Record<string, ViewModel>,
    id: "",
  },
  isLoadingSettings: false,
  flags: [],
};

export const OrganizationSettingsContext = createContext(defaultSettings);

export const OrganizationProvider = ({ children }: any) => {
  const { data } = useUserInfo();

  const organizationId = data?.user?.organization?.id;
  const { data: orgSettings, isLoading: isLoadingOrgSettings } =
    useGetOrgSettings(organizationId);

  const { data: flags, isLoading: isLoadingFlags } =
    useGetFeatureFlags(organizationId);

  const organizationSettings = deepMerge(
    defaultSettings.organizationSettings,
    orgSettings
  );

  return (
    <OrganizationSettingsContext.Provider
      value={{
        organizationSettings,
        isLoadingSettings: isLoadingOrgSettings || isLoadingFlags,
        flags,
      }}
    >
      {children}
    </OrganizationSettingsContext.Provider>
  );
};
