import type { ApplicationConfig } from ".";
import { baseConfig } from "./base.config";

export const prodConfig: ApplicationConfig = {
  ...baseConfig,
  featureFlags: {
    ...baseConfig.featureFlags,
  },
  organization: {
    ...baseConfig.organization,
    demoId: "ebf64778-0112-4230-ae83-39a5335358e5", // Aperture Inc.
  },
  documentation: {
    ...baseConfig.documentation,
    url: "https://docs.iris.arup.com/",
  },
};
