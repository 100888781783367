import { HazardsEnum } from "@/apis/services/OrganizationService";

export const defaultOrganizationSettings = {
  portfolio: {
    settings: {
      aggregate_statistics: {
        total_assets: true,
        total_population: true,
        total_asset_value: true,
        total_replacement_value: false,
        total_occupied_area: true,
        top_uses: true,
      },
      header_labels: {
        total_assets: "Total Assets",
        total_population: "Total Population",
        total_asset_value: "Total Value",
        total_replacement_value: "Total Replacement Value",
        total_occupied_area: "Occupied Area",
        top_uses: "Uses",
      },
      risk_surface_area_graph: {
        hazard: HazardsEnum.GeophysicalSeismic,
      },
      highest_risk_assets_table: {
        hazard: "all_hazards",
        consequence: "all_consequences",
      },
      portfolio_map: {
        zoom_level: 0,
        background_map: "street",
        clustering: "bubble",
      },
    },
  },
  group: {
    settings: {
      aggregate_statistics: {
        total_assets: true,
        total_population: true,
        total_asset_value: true,
        total_replacement_value: false,
        total_occupied_area: true,
        top_uses: true,
      },
      header_labels: {
        total_assets: "Total Assets",
        total_population: "Population",
        total_asset_value: "Total Value",
        total_replacement_value: "Total Replacement Value",
        total_occupied_area: "Occupied Area",
        top_uses: "Uses",
      },
      group_asset_map: {
        zoom_level: 0,
        background_map: "street",
        clustering: "bubble",
      },
      risk_surface_area_graph: {
        hazard: HazardsEnum.GeophysicalSeismic,
      },
      highest_risk_assets_table: {
        hazard: "all_hazards",
        consequence: "all_consequences",
      },
      risk_ratings_graph: {
        y_max: 20,
        consequence: "economic_loss",
        hazard: "all_hazards",
        plot_orientation: "horizontal",
      },
      risk_metrics_comparison_graph: {
        hazard: "tornado",
        limits: {
          financial_loss: {
            aggregate_aal: {
              x_min: 0,
              x_max: 50,
              y_min: 0,
              y_max: 0.17,
            },
          },
          injuries: {
            individual_aal: {
              x_min: 0,
              x_max: 50,
              y_min: 0,
              y_max: 1,
            },
            aggregate_aal: {
              x_min: 0,
              x_max: 50,
              y_min: 0,
              y_max: 1,
            },
          },
          fatalities: {
            individual_aal: {
              x_min: 0,
              x_max: 50,
              y_min: 1e-7,
              y_max: 1e-2,
            },
            aggregate_aal: {
              x_min: 0,
              x_max: 50,
              y_min: 1e-5,
              y_max: 0.07,
            },
          },
          functional_recovery: {
            aggregate_aal: {
              x_min: 0,
              x_max: 50,
              y_min: 0,
              y_max: 1,
            },
          },
          immediate_occupancy: {
            aggregate_aal: {
              x_min: 0,
              x_max: 50,
              y_min: 0,
              y_max: 365,
            },
          },
          full_recovery: {
            aggregate_aal: {
              x_min: 0,
              x_max: 50,
              y_min: 0,
              y_max: 1,
            },
          },
          damage: {
            likelihood: {
              x_min: 0,
              x_max: 50,
              y_min: 1e-6,
              y_max: 0.0012,
            },
          },
          collapse: {
            aggregate_aal: {
              x_min: 0,
              x_max: 50,
              y_min: 0,
              y_max: 1,
            },
          },
        },
      },
    },
  },
  asset: {
    settings: {
      asset_header_labels: {
        area: "Total Footprint",
        construction_year: "Construction Year",
        floors: "Floors",
        asset_name: "Name",
        nickname: "Nickname",
        population: "Occupancy",
        replacement_value: "Replacement Value",
        status: "Status",
        primary_use: "Primary Use",
        ownership: "Ownership",
        business_group: "Business Group",
      },
      single_asset_map: {
        zoom_level: 14,
        background_map: "satellite",
      },
      adjacency_map: {
        zoom_level: 4,
        center_point: [40, -93],
        background_map: "street",
      },
    },
  },
};
