import { ArrowBackIos, Dangerous, ExpandMore, Home } from "@mui/icons-material";
import {
  Stack,
  Button,
  Typography,
  Divider,
  Accordion,
  AccordionSummary,
  AccordionDetails,
} from "@mui/material";
import { useRouter } from "next/router";

import { DisplayMultipleLineText } from "./DisplayMultipleLineText";

interface ErrorProps {
  statusCode?: number;
  title: string;
  resetError?: Function;
  isAuthenticationError?: boolean;
  error?: any;
}

export const ErrorComponent = ({
  statusCode,
  title,
  resetError,
  isAuthenticationError,
  error,
}: ErrorProps) => {
  const router = useRouter();

  return (
    <Stack
      width="100%"
      height="100vh"
      alignItems="center"
      justifyContent="center"
      spacing={3}
    >
      <Stack
        direction="row"
        divider={<Divider orientation="vertical" flexItem />}
        alignItems="center"
        spacing={2}
      >
        <Dangerous fontSize="large" color="primary" />
        <Typography component="h4" variant="h5">
          {statusCode && `${statusCode}  -  `}
          {title}
        </Typography>
      </Stack>
      <Stack>
        <Stack
          direction="row"
          divider={<Divider orientation="vertical" flexItem />}
          spacing={2}
        >
          <Button
            startIcon={<ArrowBackIos color="primary" />}
            onClick={() => router.back()}
            color="inherit"
            disabled={isAuthenticationError}
          >
            Go back
          </Button>
          <Button
            endIcon={<Home color="primary" />}
            onClick={() => {
              if (resetError) {
                resetError();
              }
              router.push("/");
            }}
            color="inherit"
          >
            {isAuthenticationError ? "Login Page" : "Return home"}
          </Button>
        </Stack>
      </Stack>
      {error && (
        <Accordion sx={{ mt: 2, width: 500 }}>
          <AccordionSummary expandIcon={<ExpandMore />}>
            <Typography>View More</Typography>
          </AccordionSummary>
          <AccordionDetails
            sx={{
              background: "#ffcdd2",
              color: "#b71c1c",
              maxHeight: "500px",
              overflow: "auto",
            }}
          >
            {(error?.status || error?.error?.detail) && (
              <Typography>
                {error?.status} - {error?.error?.detail}
              </Typography>
            )}
            {error?.stack && <DisplayMultipleLineText text={error.stack} />}
          </AccordionDetails>
        </Accordion>
      )}
    </Stack>
  );
};
