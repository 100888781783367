import useSWR, { mutate } from "swr";

import type { FeatureFlag } from "@/apis/services/OrganizationService";
import { TWO_MINUTES } from "@/constants/session";
import { ALL_ORGANIZATIONS } from "@/utils/organization/organization-utils";

import { useServices } from "./useServices";

export interface Flag extends FeatureFlag {
  key: string;
}

export const useGetFeatureFlags = (orgId?: string) => {
  const { client } = useServices();
  const { data, isLoading, error } = useSWR(
    orgId ? [`getOrganizationFeatureFlags`, orgId] : null,
    async () => {
      const id = orgId === ALL_ORGANIZATIONS ? "" : orgId;
      const query = { organization_id: id };
      const flags = await client.featureFlags.getOrganizationFeatureFlags(
        query
      );
      return flags.data;
    },
    { dedupingInterval: TWO_MINUTES }
  );

  // transform the Record<key, value> into an object with a key
  const _data: Flag[] = data
    ? Object.entries(data).map(([k, v]) => ({
        key: k,
        ...v,
      }))
    : [];

  return {
    data: _data,
    isLoading: isLoading,
    isError: error,
  };
};

export const useUpsertFeatureFlag = () => {
  const { client } = useServices();
  const handleUpsertFeatureFlag = async (flag: Flag, orgId?: string) => {
    await client.featureFlags.upsertGlobalFeatureFlag(
      { flag_key: flag.key },
      flag
    );
    await mutate([`getOrganizationFeatureFlags`, orgId]);
  };
  return handleUpsertFeatureFlag;
};

export const useDeleteFeatureFlag = () => {
  const { client } = useServices();
  const handleDeleteFeatureFlag = async (key: string, orgId: string) => {
    await client.featureFlags.deleteGlobalFeatureFlag({ flag_key: key });
    await mutate([`getOrganizationFeatureFlags`, orgId]);
  };
  return handleDeleteFeatureFlag;
};

export const useUpdateFeatureFlag = () => {
  const { client } = useServices();
  const handleUpdateFeatureFlag = async (flag: Flag, orgId: string) => {
    await client.featureFlags.updateFeatureFlag(
      orgId,
      { flag_key: flag.key, enabled: flag.enabled },
      flag.attributes ?? {}
    );
    mutate([`getOrganizationFeatureFlags`, orgId]);
  };
  return handleUpdateFeatureFlag;
};
