import { Auth } from "aws-amplify";
import useSWR, { mutate } from "swr";

import type { CreateOrganizationResponseModel } from "@/apis/services/OrganizationService";
import { TWO_MINUTES } from "@/constants/session";

import { useServices } from "./useServices";

interface IMeApiCall {
  isAuthenticated: boolean;
  user?: {
    organization?: CreateOrganizationResponseModel;
    username: string;
    userId: string;
    cognito_username: string;
    roles: string[];
  };
}

export const useUserInfo = () => {
  const { client } = useServices();

  const data = useSWR<IMeApiCall>(
    "/api/me",
    async () => {
      let session;
      try {
        session = await Auth.currentSession();
      } catch (err) {
        console.error("useUserInfo :: Auth.currentSession: ", err);
        return { isAuthenticated: false };
      }

      let idToken;
      try {
        idToken = session.getIdToken();
      } catch (err) {
        console.error("useUserInfo :: Auth.currentSession.getIdToken: ", err);
        return { isAuthenticated: false };
      }

      const orgId = idToken.payload["custom:organization_id"];
      let orgApi;

      try {
        orgApi = (await client.organization.getOrganizationById(orgId)).data;
      } catch (err) {
        // this is a valid case when a user has not been assigned to an organization yet
        // in that case we show the landing page in which they can join the demo org
        console.error("getOrganizationById failed: ", err);
      }

      return {
        isAuthenticated: true,
        user: {
          organization: orgApi,
          username: idToken.payload.email,
          userId: idToken.payload.sub,
          cognito_username: idToken.payload["cognito:username"],
          roles: idToken.payload["cognito:groups"] || [],
        },
      };
    },
    {
      dedupingInterval: TWO_MINUTES,
      revalidateIfStale: true,
      revalidateOnMount: true,
      revalidateOnReconnect: true,
    }
  );

  return data;
};

export const clearCache = async (revalidate?: boolean) => {
  // clear cache
  // https://swr.vercel.app/docs/mutation#mutate-multiple-items
  await mutate(() => true, undefined, { revalidate: revalidate ?? false });
};
