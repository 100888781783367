import type { ReactNode } from "react";
import { useEffect, useMemo } from "react";

import { Authenticator } from "@aws-amplify/ui-react";
import { config } from "@fortawesome/fontawesome-svg-core";
import { useMediaQuery } from "@mui/material";
import CssBaseline from "@mui/material/CssBaseline";
import { createTheme } from "@mui/material/styles";
import { ThemeProvider } from "@mui/material/styles";
import { LicenseInfo } from "@mui/x-license-pro";
import { Amplify, Hub } from "aws-amplify";
import { ConfirmProvider } from "material-ui-confirm";
import type { NextPage } from "next";
import type { AppProps } from "next/app";
import Head from "next/head";
import { useRouter } from "next/router";
import { appWithTranslation } from "next-i18next";
import { posthog } from "posthog-js";
import { PostHogProvider } from "posthog-js/react";
import { ErrorBoundary } from "react-error-boundary";
import { mutate, SWRConfig } from "swr";

import { ErrorComponent } from "@/components/common/ErrorComponent";
import { OrganizationProvider } from "@/components/context/organizationSettingsContext";
import { ProtectedPage } from "@/components/ProtectedPage";
import { COLORS } from "@/constants/colors";
import { DEFAULT_ERROR } from "@/constants/error";
import { ONE_MINUTE } from "@/constants/session";
import { useUserInfo } from "@/hooks/useUserInfo";
import { getAppStyle } from "@/styles/AppStyle";
import { getAWSConfiguration } from "@/utils/environment";

// The following import prevents a Font Awesome icon server-side rendering bug,
// where the icons flash from a very large icon down to a properly sized one:
import "@fortawesome/fontawesome-svg-core/styles.css";
// Prevent fontawesome from adding its CSS since we did it manually above
config.autoAddCss = false;

import "@mapbox/mapbox-gl-geocoder/dist/mapbox-gl-geocoder.css";
import "mapbox-gl/dist/mapbox-gl.css";

const key = process.env.NEXT_PUBLIC_MUI_LICENSE_KEY ?? "";
LicenseInfo.setLicenseKey(key);

const isPosthogEnabled = process.env.NEXT_PUBLIC_POSTHOG_STATUS === "enabled";

if (typeof window !== "undefined") {
  if (isPosthogEnabled) {
    const enablePosthogDebug = false;
    const postHogKey = process.env.NEXT_PUBLIC_POSTHOG_KEY ?? "";
    const postHogHost = process.env.NEXT_PUBLIC_POSTHOG_HOST ?? "";
    posthog.init(postHogKey, {
      api_host: postHogHost || "https://app.posthog.com",
      loaded: (posthog) => {
        if (process.env.NODE_ENV === "development")
          posthog.debug(enablePosthogDebug);
      },
      advanced_disable_feature_flags: true,
    });
  }

  Amplify.configure({ ...getAWSConfiguration() });
}

export type Page<P = {}> = NextPage<P> & {
  getLayout?: (page: ReactNode) => ReactNode;
};

type Props = AppProps & {
  Component: Page;
};

const MyApp = ({ Component, pageProps }: Props) => {
  const router = useRouter();
  const { data } = useUserInfo();
  const getLayout = Component.getLayout || ((page: any) => page);

  useEffect(() => {
    const listener = async (data: any) => {
      switch (data.payload.event) {
        case "signIn":
        case "signUp":
        case "signOut":
        case "tokenRefresh":
        case "tokenRefresh_failure":
        case "configured":
          mutate("/api/me");
      }
    };

    Hub.listen("auth", listener);

    // Track page views using PostHog
    const handleRouteChange = () => {
      const role = data?.user?.roles?.[0];
      const orgId = data?.user?.organization?.id;
      const properties = { "Role: ": role, "Organization id: ": orgId };
      posthog?.capture("$pageview", properties);
    };
    router.events.on("routeChangeComplete", handleRouteChange);
    return () => router.events.off("routeChangeComplete", handleRouteChange);
  }, []);

  const prefersDarkMode = useMediaQuery("(prefers-color-scheme: dark)");
  const theme = useMemo(
    () =>
      createTheme(
        getAppStyle(
          {
            primary: COLORS.primary,
            secondary: COLORS.secondary,
          },
          prefersDarkMode ? "light" : "light"
        ) as any
      ),
    [prefersDarkMode]
  );

  return (
    <Authenticator.Provider>
      <PostHogProvider client={posthog}>
        <OrganizationProvider>
          <SWRConfig
            value={{
              dedupingInterval: ONE_MINUTE,
              revalidateOnFocus: false,
              shouldRetryOnError: true,
              errorRetryCount: 1,
            }}
          >
            <ThemeProvider theme={theme}>
              <ConfirmProvider>
                <Head>
                  <link rel="icon" href="/favicon.ico" />
                </Head>
                <CssBaseline />
                <ErrorBoundary
                  key={Math.random()}
                  FallbackComponent={({ error, resetErrorBoundary }) => {
                    return (
                      <ErrorComponent
                        title={DEFAULT_ERROR}
                        resetError={resetErrorBoundary}
                        error={error}
                      />
                    );
                  }}
                >
                  <ProtectedPage>
                    {getLayout(<Component {...pageProps} />)}
                  </ProtectedPage>
                </ErrorBoundary>
              </ConfirmProvider>
            </ThemeProvider>
          </SWRConfig>
        </OrganizationProvider>
      </PostHogProvider>
    </Authenticator.Provider>
  );
};
export default appWithTranslation(MyApp);
