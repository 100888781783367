import { Box, Typography } from "@mui/material";
import { ReactMarkdown } from "react-markdown/lib/react-markdown";

import { DASHED_BORDER } from "@/styles/BorderStyle";

type DisplayMultipleLineTextProps = {
  text?: string | null;
  styles?: any;
  variant?: any;
  emptyText?: string;
};

export const DisplayMultipleLineText = (
  props: DisplayMultipleLineTextProps
) => {
  const { text, styles, variant, emptyText } = props;
  const style = { pr: 2, ...styles };

  const markdownText = (text ?? "").replaceAll(/\n/g, "  \n");

  if (!markdownText) {
    return (
      <Box
        display="flex"
        justifyContent="center"
        alignItems="center"
        minHeight={80}
        sx={{ ...DASHED_BORDER, ...styles }}
      >
        <Typography fontWeight="300" fontStyle="italic" color="grey">
          {emptyText ?? "Click here to add an executive summary 📝"}
        </Typography>
      </Box>
    );
  }

  return (
    <Typography
      sx={style}
      variant={variant}
      maxHeight={900}
      overflow="auto"
      data-test="executive-summary-text"
    >
      <ReactMarkdown>{markdownText}</ReactMarkdown>
    </Typography>
  );
};
