export const NOT_FOUND = {
  ASSET: "Asset not found!",
  DEFAULT: "This page could not be found!",
};
export const DEFAULT_ERROR = "Something went wrong!";
export const STRATEGIES_ERROR_TEXTS = {
  ERROR: "There was an error while retrieving the strategies",
  NO_STRATEGY_YET: "No strategies and/or activities have been added yet.",
  DELETE_ERROR: "There was an error while deleting the strategy",
  ASSIGN_ERROR: "There was an error while assigning the strategy to the asset",
  ORDER_ERROR: "There was an error while ordering the strategies.",
  REJECT_ERROR: "There was an error while rejecting the strategy",
};
export const ACTIVITY_ERROR_TEXTS = {
  ERROR: "There was an error while retrieving the activities",
  NO_ACTIVITIES_YET: "No activities have been added to this strategy yet",
  DELETE_ERROR: "There was an error while deleting the activity",
  ASSIGN_ERROR:
    "There was an error while assigning the activity to the strategy",
};
export const COMMENT_ERROR_TEXTS = {
  CREATE: "Comment could not be added",
  LOADING: "There was an error while retrieving the comments",
  NO_COMMENT_YET: "No comment has been added yet",
};

export const PASSWORD_POLICY_ERRORS = {
  MINLENGTH: "Password must be at least 8 characters.",
  PATTERN:
    "Password must contain at least one uppercase letter, one lowercase letter, one number and one special character.",
};
