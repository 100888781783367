export const ALL_ORGANIZATIONS = "all_organizations";

export const isAll = (orgId?: string) => {
  return orgId === ALL_ORGANIZATIONS;
};

export const isPathSupported = (path: string) => {
  if (path.includes("users")) return false;
  if (path.includes("settings")) return false;
  return true;
};
