import { devConfig } from "./dev.config";
import { prodConfig } from "./prod.config";
import { testConfig } from "./test.config";
import type { FeatureFlags } from "./utils/flags";

export interface ApplicationConfig {
  featureFlags: FeatureFlags;
  apiKeys: ApiKeys;
  organization: Organization;
  documentation: Documentation;
  user: User;
}

interface ApiKeys {
  mapBoxToken: string;
}

interface Organization {
  demoId: string;
}
interface Documentation {
  url: string;
}

interface User {
  provider: string;
}
interface ConfigLookup {
  [key: string]: ApplicationConfig;
}

// Currently we have to update the local files and do a redeploy for the changes to show
// Ideally the configuration items will come from an API at some point so that all we have to do
// is a refresh in the application and the latest feature flags are loaded.
const configLookup: ConfigLookup = {
  localhost: devConfig,
  "web.dev.iris.arup.com": devConfig,
  "web2.dev.iris.arup.com": devConfig,
  "web.test.iris.arup.com": testConfig,
  "web.iris.arup.com": prodConfig,
};

const getConfig = (): ApplicationConfig => {
  if (typeof window === "undefined") return prodConfig;

  // the host property can be overwritten using environment variables
  const envHost = process.env.NEXT_PUBLIC_HOST;
  const host = envHost || window.location.hostname || "web.dev.iris.arup.com";
  return configLookup[host] || prodConfig;
};

export const updateFeatureFlag = (flag: string, value: boolean) => {
  getConfig().featureFlags[flag] = value;
};

export const flags: FeatureFlags = getConfig().featureFlags;
export const apiKeys: ApiKeys = getConfig().apiKeys;
export const organization: Organization = getConfig().organization;
export const documentation: Documentation = getConfig().documentation;
export const user: User = getConfig().user;
