import { useEffect } from "react";

import {
  Authenticator,
  Heading,
  useAuthenticator,
} from "@aws-amplify/ui-react";
import { Divider, Grid, Paper, Typography } from "@mui/material";
import { Auth } from "aws-amplify";
import Head from "next/head";
import Image from "next/image";

import { user } from "@/config";

import "@aws-amplify/ui-react/styles.css";

export const SignIn = () => {
  const { route } = useAuthenticator((context) => [context.route]);

  useEffect(() => {
    if (route !== "signIn") return;

    const usernameField: HTMLInputElement | null =
      document.querySelector("[name='username'");
    if (!usernameField) return;

    const onFocusout = async (event: any) => {
      if (event.target.value.includes("@arup.com")) {
        // ARUP SSO
        const provider = user.provider || "arup-azure-ad";
        await Auth.federatedSignIn({ customProvider: provider });
      }
    };

    usernameField.addEventListener("focusout", onFocusout);
    return () => usernameField?.removeEventListener("focusout", onFocusout);
  }, [route]);

  const components = {
    SignIn: {
      Header() {
        return (
          <Heading paddingTop={24} paddingLeft={24} level={4}>
            Sign in to your account
          </Heading>
        );
      },
    },
  };

  const formFields = {
    signIn: {
      username: {
        isRequired: true,
      },
      password: {
        isRequired: false,
      },
    },
  };

  type SignInProps = { username: string; password: string };
  const services = {
    async handleSignIn({ username, password }: SignInProps) {
      if (username.includes("@arup.com")) {
        const provider = user.provider || "arup-azure-ad";
        return await Auth.federatedSignIn({ customProvider: provider });
      }
      return await Auth.signIn(username, password);
    },
  };

  return (
    <>
      <Head>
        <title>Welcome to IRIS</title>
      </Head>
      <Grid container component="main" sx={{ height: "100vh" }}>
        <style global jsx>{`
          :root {
            --amplify-components-authenticator-router-border-color: white;
            --amplify-components-button-primary-background-color: red;
            --amplify-components-button-link-color: red;
            --amplify-components-button-primary-hover-background-color: red;
            --amplify-components-button-hover-border-color: lightgray;
            --amplify-components-button-border-color: lightgray;
            --amplify-components-textfield-border-color: lightgray;
            --amplify-components-textfield-focus-border-color: red;
            --amplify-components-fieldcontrol-focus-box-shadow: none;
            --amplify-components-flex-gap: 20px;
          }
        `}</style>
        <Grid
          item
          xs={false}
          sm={4}
          md={7}
          sx={{
            backgroundImage: "url('../../login-banner.jpg')",
            backgroundRepeat: "no-repeat",
            backgroundColor: "#F5F6F8",
            backgroundSize: "cover",
            backgroundPosition: "center",
          }}
        />
        <Grid
          container
          item
          xs={12}
          sm={8}
          md={5}
          component={Paper}
          elevation={6}
          square
          justifyContent="space-around"
          flexDirection="column"
        >
          <div>
            <Grid sx={{ width: 400, mx: "auto", pb: 4, textAlign: "center" }}>
              <Divider sx={{ my: 2 }} />
              <Typography
                variant="h3"
                component="span"
                sx={{ fontWeight: "bold", fontSize: 48 }}
              >
                IRIS
              </Typography>
              <Typography
                variant="h3"
                component="span"
                sx={{ fontWeight: 100, fontSize: 48 }}
              >
                by
              </Typography>
              <Image
                src="/Arup_Red.svg"
                alt="Arup Logo"
                width={120}
                height={32}
                quality={100}
                loading="eager"
              />
              <Divider sx={{ my: 2 }} />
            </Grid>
            <Authenticator
              components={components}
              formFields={formFields}
              services={services}
              hideSignUp
            />
          </div>
        </Grid>
      </Grid>
    </>
  );
};
