import type { ReactNode, ReactNodeArray } from "react";
import { useEffect } from "react";
import React from "react";

import { useUserInfo } from "@/hooks/useUserInfo";

import { SignIn } from "./SignIn";

const cognitoIdLocalStorageKey = "CognitoIdentityServiceProvider";

export interface ProtectedPageProps {
  children: ReactNode | ReactNodeArray;
}
export const ProtectedPage = (props: ProtectedPageProps) => {
  const { data } = useUserInfo();

  useEffect(() => {
    if (!data || data.isAuthenticated) return;
    if (typeof window === "undefined") return;

    // this should only be called once and only when isAuthenticated changes to false
    // at that point we know the cookie has become invalid
    const cognitoLocalStorageKeys = Object.keys(localStorage).filter((key) =>
      key.includes(cognitoIdLocalStorageKey)
    );
    cognitoLocalStorageKeys.forEach((key) => localStorage.removeItem(key));
  }, [data?.isAuthenticated]);

  if (!data) return null;
  if (data.isAuthenticated) return <>{props.children}</>;

  return <SignIn />;
};
