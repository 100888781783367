export const COLORS = {
  cellGrey: "#CCCCCC",
  lightGrey: "#979F9E",
  darkGrey: "#676F6E",
  mediumGrey: "#A6A6A6",
  offWhite: "#FAFAFA",
  paleGrey: "#EEEEEE",
  red: "#E61F2A",
  primary: "rgb(230, 30, 41)",
  secondary: "rgb(0, 86, 172)",
  unacceptable: "#f0261f",
  tolerable: "#E77E2E",
  acceptable: "#6CA956",
};
