const localBaseUrl = "http://localhost:3000";
const devBaseUrl = "https://web.dev.iris.arup.com";
const dev2BaseUrl = "https://web2.dev.iris.arup.com";
const testBaseUrl = "https://web.test.iris.arup.com";
const prodBaseUrl = "https://web.iris.arup.com";

const envRedirect = process.env.NEXT_PUBLIC_REDIRECT_URL;

const localConfig = {
  REDIRECT_SIGNIN_URL: envRedirect || localBaseUrl,
  REDIRECT_SIGNOUT_URL: envRedirect || localBaseUrl,
  COGNITO_DOMAIN: "iris-arup.auth.us-west-2.amazoncognito.com",
  USER_POOL_ID: "us-west-2_e86vlUEda",
  USER_POOL_WEBCLIENT_ID: "549df96i0chuqiko7jol73tv4d",
};

const devConfig = {
  REDIRECT_SIGNIN_URL: envRedirect || devBaseUrl,
  REDIRECT_SIGNOUT_URL: envRedirect || devBaseUrl,
  COGNITO_DOMAIN: "iris-arup.auth.us-west-2.amazoncognito.com",
  USER_POOL_ID: "us-west-2_e86vlUEda",
  USER_POOL_WEBCLIENT_ID: "549df96i0chuqiko7jol73tv4d",
};
const dev2Config = {
  REDIRECT_SIGNIN_URL: envRedirect || dev2BaseUrl,
  REDIRECT_SIGNOUT_URL: envRedirect || dev2BaseUrl,
  COGNITO_DOMAIN: "iris-arup.auth.us-west-2.amazoncognito.com",
  USER_POOL_ID: "us-west-2_e86vlUEda",
  USER_POOL_WEBCLIENT_ID: "549df96i0chuqiko7jol73tv4d",
};

const testConfig = {
  REDIRECT_SIGNIN_URL: envRedirect || testBaseUrl,
  REDIRECT_SIGNOUT_URL: envRedirect || testBaseUrl,
  COGNITO_DOMAIN: "iris-arup-uat.auth.us-west-2.amazoncognito.com",
  USER_POOL_ID: "us-west-2_fV4QbGPaF",
  USER_POOL_WEBCLIENT_ID: "5e3lkjvns6t8mu74a1vqop5dp2",
};

const prodConfig = {
  REDIRECT_SIGNIN_URL: envRedirect || prodBaseUrl,
  REDIRECT_SIGNOUT_URL: envRedirect || prodBaseUrl,
  COGNITO_DOMAIN: "iris-arup-prod.auth.us-west-2.amazoncognito.com",
  USER_POOL_ID: "us-west-2_XoPZf2KgS",
  USER_POOL_WEBCLIENT_ID: "1huqcpme8tqq9g39di75up3jnh",
};

const configLookup: any = {
  localhost: localConfig,
  "web.dev.iris.arup.com": devConfig,
  "webclient.dev.iris.arup.com": devConfig,
  "web2.dev.iris.arup.com": dev2Config,
  "web.test.iris.arup.com": testConfig,
  "webclient.test.iris.arup.com": testConfig,
  "web.iris.arup.com": prodConfig,
  "webclient.iris.arup.com": prodConfig,
};

const getEnvironmentConfiguration = () => {
  // the host property can be overwritten using environment variables
  const envHost = process.env.NEXT_PUBLIC_HOST;
  const host = envHost || window.location.hostname || "web.dev.iris.arup.com";
  return configLookup[host];
};

export const getAWSConfiguration = () => {
  const env = getEnvironmentConfiguration();

  return {
    Auth: {
      oauth: {
        domain: `${env.COGNITO_DOMAIN}`,
        scope: ["email", "profile", "openid", "aws.cognito.signin.user.admin"],
        redirectSignIn: `${env.REDIRECT_SIGNIN_URL}`,
        redirectSignOut: `${env.REDIRECT_SIGNOUT_URL}`,
        responseType: "code",
      },
      mandatorySignIn: false,
      region: "us-west-2",
      userPoolId: `${env.USER_POOL_ID}`,
      userPoolWebClientId: `${env.USER_POOL_WEBCLIENT_ID}`,
      authenticationFlowType: "USER_PASSWORD_AUTH",
    },
  };
};

const getBaseUrl = () => {
  let baseUrl = process.env.NEXT_PUBLIC_BASE_URL;
  if (!baseUrl && typeof window !== "undefined") {
    baseUrl = window.location.origin;
  }
  return baseUrl || devBaseUrl;
};

export const getServicesConfiguration = () => {
  const env = process.env;
  const baseUrl = getBaseUrl();

  return {
    services: {
      CLIENT_SVC_URL: env.NEXT_PUBLIC_CLIENT_SVC_URL || `${baseUrl}/client_svc`,
      HAZARD_SVC_URL: env.NEXT_PUBLIC_HAZARD_SVC_URL || `${baseUrl}/hazard_svc`,
      ASSET_SVC_URL: env.NEXT_PUBLIC_ASSET_SVC_URL || `${baseUrl}/asset_svc`,
      RECOMMENDATION_SVC_URL:
        env.NEXT_PUBLIC_RECOMMENDATION_SVC_URL || `${baseUrl}/rec_svc`,
      HAPI_SVC_URL: env.NEXT_PUBLIC_HAPI_SVC_URL || `${baseUrl}/hapi_svc`,
      OPEN_SEARCH_URL:
        env.NEXT_PUBLIC_OPEN_SEARCH_SVC_URL || `${baseUrl}/search_svc`,
    },
  };
};
