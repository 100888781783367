import { Auth } from "aws-amplify";

import {
  AssetService,
  HazardService,
  OrganizationService,
  RecommendationsService,
  HapiService,
  OpenSearchService,
} from "@/apis/services";
import { getServicesConfiguration } from "@/utils/environment";

export const useServices = (auth: any = Auth) => {
  const getJWT = async () => {
    let session = null;
    let idToken = null;
    let jwtToken = null;

    try {
      session = await auth.currentSession();
    } catch (err) {
      console.error("useServices :: getJWT :: auth.currentSession: ", err);
      return "";
    }

    try {
      idToken = session.getIdToken();
    } catch (err) {
      console.error("useServices :: getJWT :: idToken: ", err);
      return "";
    }

    try {
      jwtToken = idToken.getJwtToken();
    } catch (err) {
      console.error("useServices :: getJWT :: jwtToken: ", err);
      return "";
    }

    return `Bearer ${jwtToken}`;
  };

  const customFetch = async (
    input: any /* RequestInfo | URL */,
    init?: RequestInit
  ) => {
    return fetch(input, {
      ...init,
      headers: { ...init?.headers, Authorization: await getJWT() },
    });
  };

  const { services } = getServicesConfiguration();
  return {
    client: new OrganizationService.Api({
      customFetch: customFetch,
      baseUrl: services.CLIENT_SVC_URL,
    }),
    hazard: new HazardService.Api({
      customFetch: customFetch,
      baseUrl: services.HAZARD_SVC_URL,
    }),
    asset: new AssetService.Api({
      customFetch: customFetch,
      baseUrl: services.ASSET_SVC_URL,
    }),
    recommendation: new RecommendationsService.Api({
      customFetch: customFetch,
      baseUrl: services.RECOMMENDATION_SVC_URL,
    }),
    hapi: new HapiService.Api({
      customFetch: customFetch,
      baseUrl: `${services.HAPI_SVC_URL}`,
    }),
    openSearch: new OpenSearchService.Api({
      customFetch: customFetch,
      baseUrl: `${services.OPEN_SEARCH_URL}`,
    }),
  };
};
